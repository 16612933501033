<template>
  <div class="add-shipment">

    <div class="max-width">
      <v-stepper class="mt-14" v-model="step">
        <!-- steps head -->
        <v-stepper-header>
          <v-stepper-step :editable="false" :complete="step > 1" step="1">
            <span class="font-22" v-if="!isMobile">{{$t('enterDetails')}}</span>
          </v-stepper-step>
          
          <v-stepper-step step="2">
            <span class="font-22" v-if="!isMobile">{{$t('enterAddress')}}</span>
          </v-stepper-step>
        </v-stepper-header>

        <!-- steps content -->
        <v-stepper-items>
          <!-- phone step -->
          <v-stepper-content step="1"
            ><enter-details-step @next="stepOneHandler"></enter-details-step>
          </v-stepper-content>

          <!-- paypal step -->
          <v-stepper-content step="2"
            ><enter-address-step
              @submit="stepTwoHandler"
              @back="step = 1"
              :loading.sync="loading"
            ></enter-address-step>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>

    <!-- verify dialog -->
    <v-dialog v-model="addDialog" max-width="400" content-class="white">
      <add-shipment-dialog
        :title="$t('shipmentSuccessfullyAdded')"
        :goTo="$t('goToMyShipments')"
        @close="$router.push('/profile/shipments')"
      ></add-shipment-dialog>
    </v-dialog>
  </div>
</template>

<script>
import AddShipmentDialog from "../../components/dialogs/AddShipment.vue";
import EnterAddressStep from "./components/EnterAddress.vue";
import EnterDetailsStep from "./components/EnterDetails";
export default {
  components: {
    EnterAddressStep,
    EnterDetailsStep,
    AddShipmentDialog,
  },
  data: () => ({
    step: 1,
    addDialog: false,
    loading: false,
    obj: {},
    images: [],
    step1Obj: {},
    step2Obj: {},
  }),
  methods: {
    stepOneHandler(value) {
      this.step1Obj = { ...value };
      value.images.forEach((img) => {
        this.images.push(img.file);
      });
      this.step = 2;
    },
    stepTwoHandler(value) {
      this.step2Obj = { ...value };
      this.handleSubmit();
    },
    async handleSubmit() {
      this.loading = true;
      this.obj = { ...this.step1Obj, ...this.step2Obj };
      let payload = {
        isEdit: false,
        images: this.images,
        obj: this.obj,
      };
      let res = await this.$store.dispatch("submitShipment", payload);
      if (res.data.success) {
        this.$store.dispatch("showSnack", {
          text: "Shipment Added Successfully",
          color: "success",
        });
        this.addDialog = true
        this.loading = false;

      } else {
        this.$store.dispatch("showSnack", {
          text: res.data.message,
          color: "error",
        });
      }
    },
    closeHandler() {
      this.addDialog = false;
      this.step = 1;
    },
  },
  beforeRouteEnter(_, _2, next) {
    if (
      localStorage.getItem("role") == "bringer" ||
      localStorage.getItem("role") == "sender"
    ) {
      next();
    } else if (localStorage.getItem("role") == "guest") {
      next("/verification");
    } else {
      next("/sign-in");
    }
  },
};
</script>

<style lang="scss">
.add-shipment {
  .v-stepper {
    background: transparent !important;
    box-shadow: none !important;

    &__header {
      box-shadow: none !important;
      background: transparent !important;
      max-width: max-content;
      margin: auto;
      height: min-content !important;
    }
    &__step {
      border: 1px solid $grey;
      border-radius: 15px;
      padding: 8px 80px;
      transition: 0.3s;
      margin: 0px 10px;

      &--active {
        border: 1px solid $primary;
        background: white;
      }
    }
  }
  @media (max-width: 960px) {
    .v-stepper {
      &__step {
        padding: 8px 40px;
        span {
          margin-left: 5px;
        }
      }
    }
  }
  @media only screen and (max-width: 959px) {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
    }
  }
}
</style>
